<template>
  <div class="card-list">
    <div class="card" v-for="(item, i) in courseList" :key="i" @click="goto(item.courseId, item.courseType)">
      <div class="title">
        <div class="text">
          <span v-if="item.topRightTag == 'HOT'" class="hot">热</span>
          {{ item.courseName }}
        </div>

        <!-- <div class="img">
                    <img
                        v-if="item.topRightTag == 'HOT'"
                        src="http://public-1251206464.file.myqcloud.com/pub/1595613469796050.png"
                    />

                    <img
                        v-else-if="item.topRightTag == 'BUY'"
                        src="http://public-1251206464.file.myqcloud.com/pub/1596043379185100.png"
                    />
                </div> -->
      </div>
      <div class="tag-list">
        <span class="tag" v-for="(tag, j) in item.tags" :key="j" v-bind:class="{ warn: tag.type == 'WARN' }">{{
          tag.name
        }}</span>
      </div>

      <div class="footer-info">
        <div class="teacher-list">
          <div class="teacher" v-for="(item, i) in item.teacherList" :key="i">
            <div class="avatar">
              <img :src="item.avatar" />
            </div>
            <div class="name">
              {{ item.teacherName }}
            </div>
          </div>
        </div>
        <div class="sale-info" v-if="myCourse == false">
          <div class="price-info">
            <span class="show">￥{{ item.showPrice }}</span>
            <span class="true"> <span class="small"> ￥</span>{{ item.truePrice }}</span>
          </div>
          <div class="sales">
            <span v-if="item.courseType == 0"> {{ item.scheduleCnt }} 节课 </span>
            <span style="margin-left:10px">已售:{{ item.saleCount }}人</span>
          </div>
        </div>
        <div class="action" v-else>
          <button class="btn">开始学习</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    courseList: Array,
    myCourse: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    goto(courseId, courseType) {
      console.log("..", courseId, courseType);
      if (courseType == 1) {
        this.$router.push({
          path: "/courseSet",
          query: {
            id: courseId,
          },
        });
      } else {
        this.$router.push({
          path: "/courseDetail",
          query: {
            id: courseId,
          },
        });
      }
    },
  },
  mounted() {
    // this.$EventBus.$on('onLoginWidget', (val) => {
    //     console.log('onLoginWidget', val);
    // });
  },
};
</script>

<style lang="less" scoped>
.card-list {
  background: rgb(245, 246, 248);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .card {
    //background-image: url('http://public-1251206464.file.myqcloud.com/pub/1595613469796050.png');
    //background-repeat: no-repeat;
    // background-position: right 0 top 0;
    margin: 6px 0px;
    // padding: 0 0 6px 0;
    width: 100%;
    // min-height: 9.8rem;
    background: #ffffff;
    box-shadow: 1px 1px 0 1px #ededfa;
    border-radius: 0.85rem;
    display: flex;
    flex-direction: column;
    .title {
      margin: 15px 18px 11px 18px;
      .text {
        .hot {
          display: inline-block;
          background: #fe611c;
          border-radius: 4px;
          font-weight: 600;
          font-size: 0.785rem;
          color: #ffffff;
          letter-spacing: 0.24px;
          padding: 2px 4.5px;
          margin: 0 8px 0 0;
        }
        font-size: 1.14rem;
        color: #323233;
        letter-spacing: 0.36px;
        font-weight: 600;
      }
    }
    .tag-list {
      margin: 0 18px 17px 18px;
      .tag {
        margin: 0 12px 0 0;
        padding: 1px 5px;
        background: rgba(77, 82, 209, 0.1);
        border-radius: 4px;
        font-size: 0.77rem;
        color: #4d52d1;
        letter-spacing: 0.27px;
      }
      .warn {
        background: #fef0e5;
        color: #ff8657;
      }
    }
    .footer-info {
      margin: 0 18px 16px 18px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      .teacher-list {
        display: flex;
        flex-direction: row;
        .teacher {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 0px 12px 0px 0px;
          // width: 2.91rem;
          .avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            // height: 1.82rem;
            // width: 1.82rem;
            img {
              width: 1.82rem;
              height: auto;
            }
          }
          .name {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            color: #323233;
            letter-spacing: 0.22px;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
      .sale-info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .price-info {
          .show {
            font-size: 1rem;
            color: #a6a6a6;
            letter-spacing: 0.31px;
            line-height: 17px;
            text-decoration: line-through;
            font-weight: 600;
          }
          .true {
            font-size: 1.42rem;
            color: #f86e00;
            letter-spacing: 0.58px;
            line-height: 25px;
            margin: 0px 0px 0px 0px;
            font-weight: 600;
            .small {
              font-size: 1rem;
              margin: 0;
            }
          }
        }
        .sales {
          margin: 0px 0px 0px 0px;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          font-size: 10px;
          color: #848484;
          letter-spacing: 0.22px;
        }
      }
      .action {
        .btn {
          height: 30px;
          width: 72px;
          background: #fe611c;
          border-radius: 7px;
          border: none;
          font-weight: 600;
          font-size: 0.928rem;
          color: #ffffff;
          letter-spacing: 0.3px;
        }
      }
    }
  }
}
</style>
