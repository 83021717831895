<template>
  <div class="course-list">
    <div class="header">
      <div class="left" @click="onGoBack">
        <img :src="goBackImg" />
      </div>
      <div class="center"></div>
      <div class="right"></div>
    </div>
    <div class="main">
      <div class="card-block">
        <courseList :courseList="courseList" :myCourse="false"></courseList>
      </div>
    </div>

    <div class="footer">
      <footerMenu></footerMenu>
    </div>

    <!-- <headerMask></headerMask> -->
  </div>
</template>

<script>
import { TouchEmulator } from "@/util/touch.js";
import footerMenu from "@/components/footerMenu.vue";
import courseList from "@/components/courseList.vue";
import { courseApi } from "@/api/courseApi.js";

export default {
  components: {
    footerMenu,
    courseList,
    // latestPlay,
    // headerMask, // 可以打开这里看遮挡效果
  },
  data() {
    return {
      goBackImg: require("@/assets/images/go-back.png"),
      toPath: "/",
      courseId: 0,
      courseList: [],
    };
  },
  methods: {
    getCourseList() {
      courseApi.courseSet(this.courseId).then((res) => {
        if (res.code == 0) {
          console.log("getCourseList", res);
          this.courseList = res.datas;
        }
      });
    },
    onGoBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.id) {
      this.courseId = this.$route.query.id;
    } else {
      this.courseId = 1;
    }
    this.getCourseList(this.courseId);
  },
  watch: {
    $route(to, from) {
      if (to.query && to.query.id && to.query.id != from.query.id) {
        this.courseId = to.query.id;
        this.getCourseList(this.courseId);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.course-list {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100%;
  .header {
    margin: 0 0 4px 0;
    flex: 0 0 auto;
    height: 50px;
    //flex: 0 0 auto;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    margin: 0 0 12px 0;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      img {
        margin-left: 16px;
        height: 23px;
        width: auto;
      }
    }
    .center {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 20px;
      color: #323233;
      letter-spacing: 0.44px;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 10px;
    }
    .right {
      width: 27px;
    }
  }
  .main {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    flex: 1 1 auto;
    .recom-play {
      margin: 0 8px 5px 8px;
    }
    .hot-text {
      margin: 8px 17px 4px 17px;
      font-size: 19px;
      color: #323233;
      letter-spacing: 0.42px;
      font-weight: 600;
    }
    .latest-play {
      margin: 0 5px 0px 5px;
      padding: 0;
    }
    .card-block {
      margin: 0 17px;
      flex: 1 1 auto;
      min-height: 300px;
    }
  }
  .footer {
    flex: 0 0 auto;
  }
}
</style>
